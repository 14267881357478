/** @jsx jsx */
import { jsx, Grid } from 'theme-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import ParcelList from '../../account/ParcelList';
import {
  SendParcelWidget,
  TrackingSearchWidget,
  MultiparcelWidget,
} from '../../components/widgets';
import AccountLayout from '../../account/AccountLayout';
import * as analytics from '../../utils/analytics';

export default ({ location, pageContext }) => {
  analytics.usePageCategory('paketit');
  const translate = getTranslate(useSelector(state => state.localize));
  const title = translate('account.parcels.series');

  return (
    <AccountLayout
      title={title}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      sidebar={
        <Grid gap={3}>
          <SendParcelWidget sidebar />
          <TrackingSearchWidget sidebar />
          <MultiparcelWidget data={{ visualStyle: 'Pink' }} sidebar />
        </Grid>
      }
    >
      <ParcelList state={location.state} mode="series" />
    </AccountLayout>
  );
};
